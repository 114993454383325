<template>
    <div class="community-home-page" ref="communityHome">
        <div class="pannel pannel-one" ref="pannel-one">
            <div class="w">
                <!-- <div> -->
                <!-- <div class="pannel-title">精选作品集</div>
        <div class="pannel-subtitle">根据您感兴趣的领域推荐最新优选设计</div> -->
                <div class="pannel-content" v-if="!showAll">
                    <ul class="content-left content-part">
                        <!-- :style="{ 'background-image': 'url(' + item.image + ')' }" -->
                        <li
                            v-for="item in selectList"
                            :key="item.id"
                            :class="{
                                checked: checkedType == item.id,
                                unchecked: checkedType != -1 && checkedType != item.id
                            }"
                            @click="handleCheckedType(item.id)"
                        >
                            <!-- <div class="bg-mask"></div> -->
                            <span class="title ellipsis">{{ item.name }}</span>
                            <!-- <div class="checked-mask">
                <i class="iconfont icon-duigou"></i>
              </div>
              <div class="unchecked-mask"></div> -->
                        </li>
                    </ul>
                    <!-- :class="{ flex: selectList.length < 6 }" -->
                    <div class="content-right">
                        <div class="edit-item" @click="handleChoose">
                            <i class="iconfont icon-bianji"></i>
                        </div>
                        <div class="edit-item" @click="showAll = true">
                            <i class="iconfont icon-biaoqian1"></i>
                        </div>
                    </div>
                </div>
                <div class="pannel-content" v-else>
                    <div class="content-left content-all">
                        <li :class="{ active: activeLabelId == 'all' }" @click="handleScreenByLabel('all')">全部</li>
                        <li :class="{ active: activeLabelId == 'follow' }" @click="handleScreenByLabel('follow')">感兴趣的</li>

                        <li
                            :class="{ active: activeLabelId == item.id }"
                            @click="handleScreenByLabel(item.id)"
                            v-for="item in categoryList"
                            :key="item.id"
                            class="ellipsis"
                        >
                            <el-tooltip class="item" effect="dark" :content="item.name" placement="bottom">
                                <span>{{ item.name }}</span>
                            </el-tooltip>
                        </li>
                    </div>
                    <div class="content-right">
                        <div class="edit-item" @click="showAll = false">
                            <i class="iconfont icon-fanhui"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pannel pannel-two" :style="{ paddingTop: pannelOneHeight + 10 + 'px' }">
            <div>
                <!-- <div class="pannel-title">精选图集</div> -->
                <div class="w-l pannel-content" v-loading="collectionLoading">
                    <!-- <ul class="collection-list" v-infinite-scroll="loadMore" :infinite-scroll-disabled="busy" :infinite-scroll-distance="240"> -->
                    <ul class="collection-list">
                        <li v-for="item in folderList" :key="item.id" @click="gotoCollection(item)" @contextmenu.prevent>
                            <div class="img-wrapper">
                                <div class="left-wrapper">
                                    <img :src="item.thumbnails[0]" alt="" v-if="item.thumbnails && item.thumbnails[0]" />
                                </div>
                                <div class="right-wrapper" v-if="item.thumbnails.length >= 2">
                                    <div class="img-item">
                                        <img :src="item.thumbnails[1]" alt="" v-if="item.thumbnails && item.thumbnails[1]" />
                                    </div>
                                    <div class="img-item" v-if="item.thumbnails.length >= 3">
                                        <img :src="item.thumbnails[2]" alt="" v-if="item.thumbnails && item.thumbnails[2]" />
                                    </div>
                                </div>
                            </div>
                            <div class="info-wrapper">
                                <div class="info-item">
                                    <span class="title ellipsis">{{ item.name }}</span>
                                    <!-- <el-popover
                    placement="bottom"
                    width="400"
                    trigger="hover"
                    popper-class="work-share-popper"
                  >
                    <div slot="reference" class="fx-wrapper">
                      <i class="iconfont icon-fenxiang"></i>
                    </div>
                    <div class="share-wrapper">
                      <div class="share-title">分享图片集</div>
                      <div class="share-box">
                        <el-input v-model="shareLink"></el-input>
                        <el-button
                          type="success"
                          class="copy-btn"
                          plain
                          @click="handleShareLink(data, $event)"
                          id="copy"
                          >复制链接</el-button
                        >
                      </div>
                    </div>
                  </el-popover> -->
                                    <img src="../../assets/images/icon/icon_share.png" alt="" @click.stop="handleShare(item.id)" />
                                    <!-- <i
                    class="iconfont icon-fenxiang"
                    @click.stop="handleShare(item.id)"
                  ></i> -->
                                </div>
                                <div class="info-item info-last">
                                    <span class="count-wrapper">
                                        <div v-if="item.pictureCount > 0" :title="'图片：' + item.pictureCount">
                                            <img src="../../assets/images/icon/icon_pic.png" alt="" />
                                            <!-- <i class="iconfont icon-tupian"></i> -->
                                            <span>{{ item.pictureCount }}</span>
                                        </div>
                                        <el-divider direction="vertical" v-if="item.pictureCount && item.modelCount"></el-divider>
                                        <div v-if="item.modelCount > 0" :title="'模型：' + item.modelCount">
                                            <img src="../../assets/images/icon/icon_models.png" alt="" class="icon-model" />
                                            <span>{{ item.modelCount }}</span>
                                        </div>
                                    </span>
                                    <span class="user-name" @click.stop="$router.push('/community/designer/' + item.userId)">{{
                                        $lodash.phoneNuberConvert(item.nickName)
                                    }}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <Empty v-if="isCollectionEmpty" description="暂无相关图集" />
                </div>
            </div>
        </div>
        <c-footer></c-footer>
        <!-- 图片集分享弹框 -->
        <el-dialog
            :visible.sync="shareDialogVisible"
            :close-on-click-modal="false"
            style="margin-top: 10vh"
            custom-class="work-share-popper"
            width="30%"
        >
            <div class="share-wrapper" style="padding-top: 0">
                <div class="share-title">分享作品集</div>
                <div class="share-box">
                    <el-input v-model="shareLink"></el-input>
                    <el-button type="success" class="copy-btn" plain @click="handleShareLink($event)" id="copy">复制链接</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 标签选择弹框 -->
        <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body custom-class="label-select-dialog" width="1080px">
            <div class="select-wrapper">
                <div class="title">选择你感兴趣的领域</div>
                <div class="sub-title">
                    <!-- 最少选择5个领域，最多选择10个领域 -->
                </div>
                <div class="label-content">
                    <div class="div-scroll">
                        <ul class="label-list">
                            <li
                                @click="handleSelect(item)"
                                v-for="item in categoryList"
                                :key="item.id"
                                :class="{
                                    active: chooseList.some(data => data.id == item.id)
                                }"
                            >
                                <!-- <img :src="item.image" alt="" /> -->
                                <!-- <div class="label-bg"></div> -->
                                <span class="label-name ellipsis">{{ item.name }}</span>
                                <!-- <div class="hover-mask">
                  <i class="iconfont icon-duigou"></i>
                </div> -->
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="confirm-wrapper">
                    <!-- :class="{
              disabled: chooseList.length === 0,
              active: chooseList.length > 0,
            }"
            :disabled="chooseList.length === 0" -->
                    <el-button class="confirm-btn active" @click="handleCategorySave">
                        完成选择
                        <!-- <span>{{ chooseList.length }}</span>/10个 -->
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CFooter from "@/components/CFooter/index";
import Clipboard from "clipboard";
import { differenceBy, debounce } from "lodash";
export default {
    components: {
        CFooter
    },
    data() {
        return {
            SUCCESS_CODE: 0,
            circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            screenParams: 1, // 筛选参数
            dialogVisible: false,
            selectList: [], // 选中列表
            showAll: false, // 是否显示全部标签
            activeLabel: "", // 当前选中标签
            checkedType: -1,
            folderList: [],
            busy: false,
            pageParams: {
                pageNum: 1,
                pageSize: 20,
                total: 0,
                currentPage: 0
            },
            categoryList: [], // 所有分类
            isCollectionEmpty: false, // 图集是否为空
            collectionLoading: false,
            keywords: "",
            chooseList: [],
            activeLabelId: "all",
            shareLink: "",
            shareDialogVisible: false,
            type: "",
            pannelOneHeight: 78
        };
    },
    created() {
        // this.keywords = this.$route.query.keywords;
        // this.type = this.$route.query.type;
        // this.getFolderList();
        this.getAllCateforyList();
        this.getCategoryUserList();
    },
    watch: {
        $route: {
            handler(val, old) {
                this.keywords = val.query.keywords ? val.query.keywords : "";
                this.type = val.query.type ? val.query.type : "";
                if (old === undefined) {
                    this.$store.commit("community/resetCollectionList");
                }
                this.getFolderList(true);
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        const app = document.getElementById("app");
        app.style.height = "auto";
        app.style.overflow = "auto";
        this.handleScroll();
        // this.$nextTick(() => {
        //     this.$emit("refreshReBackTop", document.getElementById("app"));
        // });
    },
    methods: {
        handleScroll() {
            window.addEventListener("scroll", this.scroll);
        },
        scroll() {
            if (this.scrollToTheEnd()) {
                this.loadMore();
            }
        },

        // 选择感兴趣的类型
        handleSelect(data) {
            let index = this.chooseList.indexOf(this.chooseList.filter(d => d.id == data.id)[0]);
            // console.log(index)
            if (index == -1) {
                // if (this.chooseList.length == 10) return;
                if(data.name==="精选推荐"){
                  this.chooseList.unshift(data);
                  return
                }
                this.chooseList.push(data);
            } else {
                this.chooseList.splice(index, 1);
            }
        },
        // 在全部类型里筛选
        handleScreenByLabel(data) {
            this.activeLabelId = data;
            // let index = this.selectList.indexOf(
            //   this.selectList.filter((d) => d.id == data)[0]
            // );
            if (data === "follow") {
                this.checkedType = -1;
                this.$store.commit("community/setCollectionListCids", this.selectList.map(item => item.id).join());
            } else if (data === "all") {
                this.checkedType = -1;
                this.$store.commit("community/resetCollectionList");
            } else {
                this.checkedType = data;
                this.$store.commit("community/setCollectionListCids", data);
            }
            this.getFolderList(true);
        },
        // 在感兴趣的类型里筛选
        handleCheckedType(data) {
            if (data == this.activeLabelId) {
                this.activeLabelId = "follow";
                this.checkedType = -1;
                // this.handleScreenByLabel('all');

                /**
                 * 不能理解
                 */
                this.cids = "";
                this.$store.commit("community/setCollectionListCids", "reset");
            } else {
                this.checkedType = data;
                this.activeLabelId = data;
                this.$store.commit("community/setCollectionListCids", data);
                // this.getFolderList(this.checkedType);
            }
            this.getFolderList(true);
        },
        // 获取图集
        getFolderList(flag) {
            if (flag) {
                this.folderList = [];
                this.pageParams = {
                    pageNum: 1,
                    pageSize: 20,
                    total: 0,
                    currentPage: 0
                };
                this.busy = false;
            }
            if (this.busy) return;
            if (this.pageParams.currentPage * this.pageParams.pageSize > this.pageParams.total) {
                this.busy = true;
                return;
            }
            let params = Object.assign({}, this.pageParams);
            params.search = this.keywords;
            params.type = this.type;
            if (this.$store.state.community.collectionList.cids === -1 || !this.$store.state.community.collectionList.cids) {
                params.cids = this.selectList?.map(item => item.id)?.join(",");
            } else {
                params.cids = this.$store.state.community.collectionList.cids;
            }
            if (!params.cids) return;
            this.collectionLoading = true;
            this.busy = true;
            this.$http
                .get("/directory-info/comDir", { params })
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        res.data.records.map(folder => {
                            folder.thumbnails = folder.resourceInfos.map(resouce => {
                                return resouce.obsThumbUrl;
                            });
                        });
                        this.folderList = this.folderList.concat(res.data.records);
                        this.pageParams.total = res.data.total;
                        this.pageParams.currentPage = res.data.current;
                        if (this.folderList && this.folderList.length) {
                            this.isCollectionEmpty = false;
                        } else {
                            this.isCollectionEmpty = true;
                        }
                    } else {
                        this.isCollectionEmpty = true;
                    }
                    this.collectionLoading = false;

                    setTimeout(() => {
                        if (res.data.current * this.pageParams.pageSize > res.data.total) {
                            this.busy = true;
                        } else {
                            this.busy = false;
                        }
                        const viewPortHeight = this.getViewPortHeight();
                        const clientHeight = this.getClientHeight();
                        if (clientHeight < viewPortHeight) {
                            this.loadMore();
                        }
                    }, 300);
                })
                .catch(err => {
                    this.isCollectionEmpty = true;
                    this.collectionLoading = false;
                    this.busy = false;
                });
        },
        //浏览器窗口可视高度
        getViewPortHeight() {
            return document.documentElement.clientHeight || document.body.clientHeight;
        },
        //取窗口可视范围的高度
        getClientHeight() {
            let clientHeight = 0;
            if (document.body.clientHeight && document.documentElement.clientHeight) {
                clientHeight =
                    document.body.clientHeight < document.documentElement.clientHeight
                        ? document.body.clientHeight
                        : document.documentElement.clientHeight;
            } else {
                clientHeight =
                    document.body.clientHeight > document.documentElement.clientHeight
                        ? document.body.clientHeight
                        : document.documentElement.clientHeight;
            }
            return clientHeight;
        },
        scrollToTheEnd() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            if (scrollHeight > clientHeight && scrollTop + clientHeight >= scrollHeight - 100) {
                return true;
            }
            return false;
        },
        // // 取窗口滚动条高度
        // getScrollTop() {
        //     let scrollTop = 0;
        //     if (document.documentElement && document.documentElement.scrollTop) {
        //         scrollTop = document.documentElement.scrollTop;
        //     } else if (document.body) {
        //         scrollTop = document.body.scrollTop;
        //     }
        //     return scrollTop;
        // },
        // getScrollHeight() {
        //     let scrollHeight = 0;

        //     if (document.documentElement && document.documentElement.scrollHeight) {
        //         scrollHeight = document.documentElement.scrollHeight;
        //     } else if (document.body) {
        //         scrollHeight = document.body.scrollHeight;
        //     }
        //     return scrollHeight;
        // },
        loadMore() {
            if (this.busy) return;
            console.log("loadMore");
            this.pageParams.pageNum++;
            this.getFolderList();
        },
        // 获取所有作品领域
        getAllCateforyList() {
            this.$http.get("/dict-category/list").then(res => {
                if (res.code == this.SUCCESS_CODE) {
                    const categoryList = []
                    if(Array.isArray(res.data)){
                      res.data.forEach(item=>{
                        if(item.name==='精选推荐'){
                          categoryList.unshift(item)
                        }else{
                          categoryList.push(item)
                        }
                      })
                    }
                    this.categoryList = categoryList;
                }
            });
        },
        // 获取感兴趣的领域
        getCategoryUserList() {
            this.$http
                .get("/dict-category/userList")
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        const selectList = []
                        if(Array.isArray(res.data)){
                          res.data.forEach(item=>{
                            if(item.name==='精选推荐'){
                              selectList.unshift(item)
                            }else{
                              selectList.push(item)
                            }
                          })
                        }
                        console.log('sl',selectList)
                        this.selectList = selectList
                        if (!res.data) this.isCollectionEmpty = true;
                        this.getFolderList(true);
                    }
                })
                .finally(() => {
                    this.pannelOneHeight = this.$refs["pannel-one"].clientHeight;
                });
        },
        //是否取消已经选中的兴趣是的话就重置
        isCancelCurrActive(state) {
            // console.log(differenceBy(this.chooseList, this.chooseList, "name"));
            const isExist = this.selectList.find(item => item.id === this.checkedType);
            if ((!isExist && this.checkedType !== -1) || state) {
                if (this.activeLabelId != this.checkedType || !isExist) {
                    this.handleCheckedType(this.checkedType);
                }
            }
        },
        // 保存用户感兴趣的图集分类
        handleCategorySave() {
            let ids = this.chooseList.map(item => item.id).join();
            let params = { ids };
            this.$http
                .post("/user-dict-category/save", null, { params })
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        let arr = [];
                        if (this.selectList.length > this.chooseList.length) {
                            arr = differenceBy(this.selectList, this.chooseList, "name");
                        } else {
                            arr = differenceBy(this.chooseList, this.selectList, "name");
                        }
                        this.selectList = [...this.chooseList];
                        this.isCancelCurrActive(arr.length !== 0);

                        this.dialogVisible = false;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(err => {
                    this.$message.success(err);
                })
                .finally(() => {
                    this.pannelOneHeight = this.$refs["pannel-one"].clientHeight;
                });
        },
        // 选择感兴趣得领域
        handleChoose() {
            this.dialogVisible = true;
            this.chooseList = [...this.selectList];
        },
        // 分享图片集
        handleShare(id) {
            this.shareLink = window.location.origin + "/#/community/collection/" + id;
            this.shareDialogVisible = true;
        },
        handleShareLink(e) {
            let linkUrl = this.shareLink;
            let clipboard = new Clipboard(e.target, { text: () => linkUrl });
            clipboard.on("success", () => {
                this.$message.success("已复制分享链接");
                // 释放内存
                clipboard.destroy();
            });
            clipboard.on("error", e => {
                console.log("复制失败", e);
                // 释放内存
                clipboard.destroy();
            });
            clipboard.onClick(e);
        },
        gotoCollection(item) {
            // 跳转图集页面
            let routeData = this.$router.resolve({
                path: "/community/collection/" + item.id + "?type=" + this.type
            });
            window.open(routeData.href, "_blank");
        }
    },
    beforeDestroy() {
        const app = document.getElementById("app");
        app.style.height = "";
        app.style.overflow = "";
        this.pageParams = {
            pageNum: 1,
            pageSize: 20,
            total: 0,
            currentPage: 0
        };
        window.removeEventListener("scroll", this.scroll);
        this.$emit("resetReBackTop");
    }
};
</script>

<style scoped></style>
