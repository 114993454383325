<template>
    <div class="c-footer">
        <div class="w footer-content">
            <div class="footer-left">
                <div class="logo">
                    <img src="../../assets/images/logo.png" alt="" />
                    <!-- <span>数字化资源云平台</span> -->
                </div>
                <div class="about">
                    <div class="about-list">
                        <span>关于</span>
                        <el-divider direction="vertical"></el-divider>
                        <span>产品与服务</span>
                        <el-divider direction="vertical"></el-divider>
                        <span>合作</span>
                        <el-divider direction="vertical"></el-divider>
                        <span>其他</span>
                    </div>
                    <!-- <div class="phone">咨询电话：400-550-8888</div> -->
                </div>
            </div>
            <div class="footer-right">
                <div class="erweima">
                    <img src="../../assets/images/contacts.jpg" alt="" />
                    <div class="desc">
                        <p>产品负责人：蒋林</p>
                        <p>咨询电话：18927499002</p>
                    </div>
                </div>
                <div class="friend-link">
                    <div class="title">友情链接</div>
                    <ul class="link-list">
                        <li v-for="item in linkList" :key="item.id" @click="goto(item)" :title="item.name">
                            <div class="logo-image">
                                <img :src="item.image" alt="" />
                            </div>
                            <div :href="item.url" class="link-text">{{ item.url }}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            linkList: [
                {
                    id: 1,
                    name: "有料",
                    image: require("../../assets/images/youliao.jpg"),
                    url: "https://henyouliao.cn/index"
                }
                // {
                //   id: 1,
                //   name: "花瓣",
                //   url: "https://huaban.com/",
                // },
                // {
                //   id: 2,
                //   name: "站酷",
                //   url: "https://www.zcool.com.cn/",
                // },
                // {
                //   id: 3,
                //   name: "UI中国",
                //   url: "https://www.ui.cn/",
                // },
                // {
                //   id: 4,
                //   name: "优设网",
                //   url: "https://www.uisdc.com/",
                // },
                // {
                //   id: 5,
                //   name: "阿里妈妈",
                //   url: "https://mux.alimama.com/",
                // },
                // {
                //   id: 6,
                //   name: "优优教程网",
                //   url: "https://uiiiuiii.com/",
                // },
                // {
                //   id: 7,
                //   name: "THX",
                //   url: "https://www.thx.com/",
                // },
            ]
        };
    },
    methods: {
        goto(data) {
            if (data.url) {
                // window.location.href = data.url;
                window.open(data.url, "_blank");
            }
        }
    }
};
</script>

<style lang="less">
.c-footer {
    display: none;
    height: 240px;
    background: #ffffff;
    //   padding: 20px;
    .footer-content {
        padding: 40px 0;
        height: 100%;
        display: flex;
        justify-content: space-between;
        .footer-left {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .logo {
                // height: 100%;
                display: flex;
                align-items: center;
                margin-right: 80px;

                > img {
                    width: 130px;
                    transform: scale(1.5);
                    transform-origin: center left;
                    // height: 100px;
                    // margin-right: 10px;
                }

                > span {
                    font-size: 20px;
                    color: #fff;
                }
            }
            .about {
                color: #000000;
                font-size: 16px;
                .about-list {
                    padding: 15px 0;
                    .el-divider--vertical {
                        margin: 0 15px;
                    }
                }
            }
        }
        .footer-right {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .erweima {
                display: flex;
                align-items: center;
                > img {
                    width: 86px;
                    height: 86px;
                    margin-right: 10px;
                }
                .desc {
                    color: #000000;
                    font-size: 16px;
                    > p {
                        line-height: 24px;
                    }
                }
            }
            .friend-link {
                .title {
                    color: #000000;
                    font-size: 16px;
                    line-height: 30px;
                    padding-top: 10px;
                    margin-bottom: 5px;
                }
                .link-list {
                    display: flex;
                    > li {
                        color: #000000;
                        cursor: pointer;
                        // padding-right: 15px;
                        margin-right: 12px;
                        display: flex;
                        align-items: center;
                        // background-color: #fff;
                        // &:hover {
                        //   color: #fff;
                        //   text-decoration: underline;
                        // }
                        .logo-image {
                            display: inline-block;
                            // padding: 10px;
                            background-color: #fff;
                            > img {
                                width: 90px;
                            }
                        }
                        .link-text {
                            // padding-top: 10px;
                            margin-left: 10px;
                            color: #000000;
                            &:hover {
                                color: #8565f6;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
